import React from 'react';
import { Form, Input, Button, Switch } from 'antd';
import {useDataProvider, useNotify, useQuery, useRefresh} from 'react-admin';
import {createSubmitHandler} from "@src/components/Settings/utils";
import {ExtendedDataProvider} from "@src/types";

const SmtpSettingsForm = () => {
  const notify = useNotify()
  const [form] = Form.useForm();
  const refresh = useRefresh();
  const dataProvider: ExtendedDataProvider = useDataProvider()
  const settingsName = "SMTP"

  const {loaded, error, data: currentSettings} = useQuery ({
    type: 'getOne',
    resource: 'general_settings',
    payload: {id: settingsName}
  });


  return (
    <>
      {
          (loaded || error) &&
      <Form form={form}
            labelAlign='left'
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            initialValues={{
              ENABLED: currentSettings?.['config']?.ENABLED
            }}
            onFinish={createSubmitHandler(settingsName, currentSettings, dataProvider, notify, refresh)}
      >

        <Form.Item valuePropName='checked' label="Enable SMTP" name="ENABLED" initialValue={currentSettings?.['config']?.ENABLED || false}>
          <Switch defaultChecked={currentSettings?.['config']?.ENABLED == true} />
        </Form.Item >

        <Form.Item
          label="Host"
          initialValue={currentSettings?.['config']?.EMAIL_HOST}
          name="EMAIL_HOST"
          rules={[{ required: true, message: 'Please input host' }]}
        >
          <Input placeholder="e.g. smtp.gmail.com" />
        </Form.Item>

        <Form.Item
          label="Port"
          initialValue={currentSettings?.['config']?.EMAIL_PORT}
          name="EMAIL_PORT"
          rules={[{ required: true, message: 'Please input port' }]}
        >
          <Input placeholder="port" />
        </Form.Item>

        <Form.Item
          label="Username"
          initialValue={currentSettings?.['config']?.EMAIL_HOST_USER}
          name="EMAIL_HOST_USER"
          rules={[{ required: true, message: 'Please input username' }]}
        >
          <Input placeholder="username" />
        </Form.Item>

        <Form.Item
          label="Password"
          initialValue={currentSettings?.['config']?.EMAIL_HOST_PASSWORD}
          name="EMAIL_HOST_PASSWORD"
          rules={[{ required: true, message: 'Please input password' }]}
        >
          <Input.Password placeholder="password" />
        </Form.Item>

        <Form.Item
          label="Use TLS"
          initialValue={currentSettings?.['config']?.EMAIL_USE_TLS || false}
          name="EMAIL_USE_TLS"
          valuePropName='checked'
          rules={[{ required: true}]}
        >
          <Switch defaultChecked={currentSettings?.['config']?.EMAIL_USE_TLS == true} />
        </Form.Item>

        <Form.Item >
          <Button type="primary" htmlType="submit" className="form-button">Save</Button>
        </Form.Item>
      </Form>
      }
    </>
  )

}

export default SmtpSettingsForm;
