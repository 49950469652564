// @ts-nocheck
import {updateSettingsLocalStorage} from "@src/providers/useGetGeneralSettings";

export const createSubmitHandler = (
    settingsName: string,
    currentSettings: any,
    dataProvider: any,
    notify: any,
    refresh: any
) => {

    return (values: any) => {
        const submission = {"name": settingsName, "config": values}

        if (currentSettings && Object.keys (currentSettings).length != 0) {
            // update existing
            dataProvider.update ('general_settings', {
                id: currentSettings.id,
                data: submission,
                previousData: {id: currentSettings.id}
            })
                .then (response => {
                    updateSettingsLocalStorage (settingsName, response.data);
                    notify ('Settings have been saved.')
                })
                .catch (error => notify ("Something went wrong", "warning"))
        } else {
            //create
            dataProvider.create ('general_settings', {data: submission})
                .then (response => {
                    updateSettingsLocalStorage (settingsName, response.data);
                    refresh ()
                    notify ('Settings have been saved.')
                }).catch (error => {
                    console.error (error)
                    notify ("Something went wrong", "warning")
                })
        }
    }
}
