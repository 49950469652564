const useGetGeneralSettings = (): any[] => {
    return JSON.parse(localStorage.getItem('general_settings')!) || []
}

export const updateSettingsLocalStorage = (settingsName: string, newData: any) => {
    const generalSettings = useGetGeneralSettings();
    const index = generalSettings.findIndex(obj => obj.name === settingsName);
    if (index !== -1) {
        generalSettings[index] = newData;  // Replace the entire object
    } else {
        generalSettings.push(newData)
    }
    localStorage.setItem('general_settings', JSON.stringify(generalSettings));
}

export default useGetGeneralSettings;
