import React from "react";
import { Typography } from "antd";
import {InfoCircleOutlined} from "@ant-design/icons";

const { Text } = Typography;


const ChangeInstanceLog = ({ change_instance }: { change_instance: Record<string, any> }) => {
    return (
        <>
            {
                change_instance.log!=="" &&
                <div style={{float: 'left', margin: '10px'}}>
                    <InfoCircleOutlined /> {"Log: "} <br/>
                    <Text style={{ whiteSpace: 'pre-line', wordWrap: 'break-word', wordBreak: 'break-word',}}>{change_instance.log}</Text>
                </div>
            }
        </>
    )
}

export default ChangeInstanceLog;
