import React from "react";
import { Typography } from "antd";
import DeclarationDiff from "./DeclarationDiff";
import ChangeInstanceLog from "@src/components/ChangeInstances/ChangeInstanceLog";

const { Text } = Typography;

// Used in change instance list and submission list pages

const ConsumerChangeInstanceExpandedRow = ({ record }: { record: Record<string, any> }) => {
    return (
        <>
            <ChangeInstanceLog change_instance={record} />
            <DeclarationDiff change_instance={record} />
        </>
    )
}

export default ConsumerChangeInstanceExpandedRow;
