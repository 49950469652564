import {useDataProvider, useNotify, useQueryWithStore, useRefresh} from "react-admin";
import React from "react";
import {Button, Form, Select, Switch} from "antd";
import {ExtendedDataProvider} from "../../types";
import {createSubmitHandler} from "@src/components/Settings/utils";

const CurrencySettingsForm = () => {
    const notify = useNotify ()
    const [form] = Form.useForm ();
    const refresh = useRefresh();
    const dataProvider: ExtendedDataProvider = useDataProvider ()
    const settingsName = "Currency"

    const {loaded, error, data: currentSettings} = useQueryWithStore ({
        type: 'getOne',
        resource: 'general_settings',
        payload: {id: "Currency"}
    });

    return (
        <>
            {
                (loaded || error) &&
                <Form
                    form={form}
                    labelAlign='left'
                    labelCol={{span: 8}}
                    wrapperCol={{span: 16}}
                    initialValues={{ ENABLED: currentSettings?.['config']?.ENABLED }}
                    onFinish={createSubmitHandler(settingsName, currentSettings, dataProvider, notify, refresh)}
                >

                    <Form.Item valuePropName='checked' label={"Enable " + settingsName} name="ENABLED" initialValue={currentSettings?.['config']?.ENABLED || false}>
                        <Switch defaultChecked={currentSettings?.['config']?.ENABLED == true}/>
                    </Form.Item>

                    <Form.Item
                        label={settingsName}
                        initialValue={currentSettings?.['config']?.currency}
                        name="currency"
                        rules={[{required: true, message: 'Please input currency'}]}
                    >
                        <Select
                            style={{ width: 120 }}
                            options={[
                                { value: 'GBP', label: 'GBP' },
                                { value: 'EUR', label: 'EUR' },
                                { value: 'USD', label: 'USD' },
                            ]}
                        />
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" htmlType="submit" className="form-button">Save</Button>
                    </Form.Item>
                </Form>
            }
        </>
    )
}

export default CurrencySettingsForm;
