
import { TitleFieldProps } from "@rjsf/utils";
import { Typography } from 'antd';
import 'antd/es/alert/style/index.css';
import 'antd/es/input-number/style/index.css';
import React from 'react';
const { Title, Text } = Typography

function TitleFieldTemplate(props: TitleFieldProps) {
    const { title, uiSchema, id, schema, registry } = props;
    
    // Extract the field name from the id (e.g., "sources" from "142_sources__title")
    const fieldMatch = id?.match(/\d+_(\w+)__/);
    const fieldName = fieldMatch?.[1];

    // Check if this field is a child of an anyOf by looking at the root schema
    const schemaProperty = fieldName ? registry.rootSchema.properties?.[fieldName] : undefined;
    const isAnyOfChild = fieldName && typeof schemaProperty === 'object' && 'anyOf' in schemaProperty;

    // Hide titles inside arrays (matches patterns like: xxx_0, xxx_0_yyy, or xxx-0)
    // Or hide titles for anyOf children
    // show title for anyOf root
    if (id && (/[_-]\d+(_|$)/.test(id)  || isAnyOfChild ) && !schema.anyOf) {
        return null;
    }
 
    //Only display root title
    if (uiSchema && Object.keys(uiSchema).length === 0) {
        return (
            <Title style={{ textAlign: 'left' }} level={5}>
                {title}
            </Title>
        )
    }
    return (
        <Title style={{ textAlign: 'left', marginBottom: '0' }} level={4}>
            {title}
        </Title>
    );
}

export default TitleFieldTemplate;