import * as React from 'react';
import {Card, Collapse, Divider, Typography} from "antd";
import LdapSettingsForm from "@src/components/Settings/LdapSettingsForm";
import SmtpSettingsForm from "@src/components/Settings/SmtpSettingsForm";
import FeedbackSettingsForm from "@src/components/Settings/FeedbackSettingsForm";
import GoogleOAuthSettingsForm from "@src/components/Settings/GoogleOAuthSettingsForm";
import CurrencySettingsForm from "@src/components/Settings/CurrencySettingsForm";
import ChargesSettingsForm from "@src/components/Settings/ChargesSettingsForm";
import SubmissionBuilderSettingsForm from "@src/components/Settings/SubmissionBuilderSettingsForm";
import {DownOutlined} from "@ant-design/icons";

const { Panel } = Collapse;
const { Title } = Typography;

const customExpandIcon = (panelProps: any) => (
    <DownOutlined
        style={{
            fontWeight: "bold",
            marginTop: '8px',
            fontSize: '16.5px',
            transform: panelProps.isActive ? 'rotate(180deg)' : 'rotate(0deg)',
            transition: 'transform 0.2s',
        }}
    />
);


const GeneralSettingsList = () => {
    const namesToComp = [
        ['LDAP Settings', <LdapSettingsForm />],
        ["SMTP Settings", <SmtpSettingsForm />],
        ["Feedback Settings", <FeedbackSettingsForm />],
        ["Google OAuth Settings", <GoogleOAuthSettingsForm />],
        ["Currency Settings", <CurrencySettingsForm />],
        ["Charges Settings", <ChargesSettingsForm />],
        ["Submission Builder Settings", <SubmissionBuilderSettingsForm />],
    ];
    return (
        <Card>
            <Collapse defaultActiveKey={[]} style={{margin: '10px'}} ghost={true} expandIcon={customExpandIcon}>
                {
                    namesToComp.map((subList, outerIndex)=>{
                        const title = subList[0];
                        const component = subList[1];
                        return (
                            <>
                                <Panel header={<Title level={4}>{" "}{title}</Title>} key={outerIndex}>
                                    <Card style={{padding: "10px"}}>{component}</Card>
                                </Panel>
                                <Divider />
                            </>

                        )
                    })
                }
            </Collapse>
        </Card>
    )
}

export default GeneralSettingsList