// @ts-nocheck
import { faCogs, faListUl, faRocket, faThLarge, faUserFriends, faPlug, faCloudUploadAlt, faHeartbeat, faWrench } from '@fortawesome/free-solid-svg-icons';
import * as React from "react";
import { Admin, Resource } from 'react-admin';
import Applications from './components/Applications';
import ChangeInstances from './components/ChangeInstances';
import Dashboard from "./components/Dashboard";
import Webhooks from "./components/Webhooks";
import Healthchecks from "./components/Healthchecks";
import FaIcon from "./components/Icons/FaIcon";
import Layout from './components/Layout';
import LoginPage from './components/Login/LoginPage';
import ServiceCatalogue from "./components/ServiceCatalogue";
import ServiceItems from './components/ServiceItems';
import Submissions from './components/Submissions';
import ServiceOwnerChangeInstances from './components/ServiceOwnerChangeInstances';
import ServiceOwnerServiceItems from "./components/ServiceOwnerServiceItems";
import Services from './components/Services';
import Teams from './components/Teams';
import Marketplace from "./components/Marketplace";
import drfProvider, { fetchJsonWithAuthToken, tokenAuthProvider } from './providers/dataProvider';
import uiReducer from './reducers/ui';
import Routes from './routes';
import './assets/css/main.css';
import './assets/css/responsive.css';
import { GoogleOAuthProvider } from '@react-oauth/google';
import Settings from "@src/components/Settings";

const dataProvider = drfProvider(process.env.API_URL, fetchJsonWithAuthToken)
const authProvider = tokenAuthProvider();

const App_ = () => (

  <Admin dataProvider={dataProvider}
    authProvider={authProvider}
    disableTelemetry
    dashboard={Dashboard}
    customRoutes={Routes}
    initialState={{ admin: { ui: { sidebarOpen: false } } }}
    customReducers={{ ui: uiReducer }}
    layout={Layout} loginPage={LoginPage}  >
    <Resource name="teams" {...Teams} icon={FaIcon(faUserFriends)} options={{ 'belowFold': true }} />
    <Resource name="services" {...Services} icon={FaIcon(faRocket)} options={{ 'viewMode': 'SERVICE_OWNER' }} />
    <Resource name="browse_services" {...ServiceCatalogue} icon={FaIcon(faListUl)} options={{ 'viewMode': 'CONSUMER', label: 'Service Catalogue' }} />
    <Resource name="submissions" {...Submissions} icon={FaIcon(faCloudUploadAlt)} options={{ 'viewMode': 'CONSUMER', 'belowFold': true, label: 'Submissions' }} />
    <Resource name="service_items" {...ServiceItems} icon={FaIcon(faRocket)} options={{ 'viewMode': 'CONSUMER', label: 'Service Items' }} />
    <Resource name="owner_service_items" {...ServiceOwnerServiceItems} icon={FaIcon(faListUl)} options={{ 'viewMode': 'SERVICE_OWNER', label: 'Service Items' }} />
    <Resource name="applications" {...Applications} icon={FaIcon(faThLarge)} options={{ 'viewMode': 'CONSUMER' }} />
    <Resource name="change_instances" {...ChangeInstances} icon={FaIcon(faCogs)} options={{ 'viewMode': 'CONSUMER', label: 'Change Instances' }} />
    <Resource name="owner_change_instances" {...ServiceOwnerChangeInstances} icon={FaIcon(faCogs)} options={{ 'viewMode': 'SERVICE_OWNER', label: 'Change Instances' }} />
    <Resource name="webhooks" {...Webhooks} icon={FaIcon(faPlug)} options={{ 'viewMode': 'SERVICE_OWNER', label: 'Webhooks' }} />
    <Resource name="serviceowner_healthchecks" {...Healthchecks} icon={FaIcon(faHeartbeat)} options={{ 'viewMode': 'SERVICE_OWNER', label: 'Healthchecks' }} />
    <Resource name="serviceowner_charges" {...Marketplace} options={{ 'viewMode': false }} />
    <Resource name="consumer_charges" {...Marketplace} options={{ 'viewMode': false }} />
    <Resource name="general_settings" {...Settings} icon={FaIcon(faWrench)} options={{ 'viewMode': 'superuser', label: 'General Settings' }} />

  </Admin>
);

const App = () => {
  return (
    process.env.GOOGLE_OAUTH_CLIENT_ID ?
      <GoogleOAuthProvider clientId={process.env.GOOGLE_OAUTH_CLIENT_ID}>
        <App_ />
      </GoogleOAuthProvider>
      :
      <App_ />
  )
}


export default App;