import {CheckCircleOutlined, InfoCircleOutlined, WarningOutlined} from '@ant-design/icons';
import {Alert, Button, Card, Form, Modal, Space, Typography} from 'antd';
import React, { useState } from 'react';
import {useDataProvider, useNotify, usePermissions} from 'react-admin';
import Editor from '@monaco-editor/react';
import DeclarationDiff from "../ChangeInstances/DeclarationDiff";
import TextArea from "antd/es/input/TextArea";
import ChangeInstanceLog from "@src/components/ChangeInstances/ChangeInstanceLog";

const { confirm } = Modal;

const ChangeInstanceRow = ({ record, refresh }: { record: Record<string, any>, refresh: Function }) => {
    const { permissions } = usePermissions();
    const [isUpdateFormVisible, setIsUpdateFormVisible] = useState(false);
    const [completeChangeInstanceForm] = Form.useForm();
    const [logForm] = Form.useForm();
    const dataProvider = useDataProvider();
    const notify = useNotify();

    const logModalMessageGenerator = (newState: string) => {
        if(newState==="ERROR")
            return "Please provide a reason for error below (optional):"

        else if(newState==="REJECTED")
            return "Please provide a reason for rejection below (optional):"

        else if(newState==="APPROVED")
            return "Additional Information (optional):"

        else if(newState==="COMPLETED")
            return "Additional Information (optional):"

    }

    const onSubmitCompleteChangeInstance = (values: any) => {
        setIsUpdateFormVisible(false)
        const log_ = completeChangeInstanceForm.getFieldValue("log")
        const submissionData: any = { state: "COMPLETED", log: log_ }

        const deployed_item = values?.deployed_item
        if(deployed_item)
            if(!(deployed_item==="" || deployed_item=={}))
                submissionData['deployed_item'] = JSON.parse(deployed_item)

        dataProvider.update('owner_change_instances', { id: record.id, data: submissionData, previousData: { id: record.id } })
            .then(response => {
                refresh();
                notify("Change Instance's state has been updated")
            }).catch(error => {
                notify("Something went wrong", "warning")
            })
    }

    const updateChangeInstance = (record: Record<string, any>, newState: string) => {
        const updateCI = () => {
            const log_ = logForm.getFieldValue("log")
            const updateCIData = {state: newState, log: log_};

            dataProvider.update(
                'owner_change_instances', {
                    id: record.id,
                    data: updateCIData,
                    previousData: { id: record.id }
                })
                .then(response => {
                    refresh();
                    notify("Change Instance's state has been updated")
                })
                .catch(error => notify("Something went wrong", "warning"))
        };

        if (["REJECTED", "ERROR", "APPROVED"].includes(newState)) {
            const alertMessage = (newState=="REJECTED"?'Rejecting':'Erroring') + " this change instance will require the customer to modify their declaration before they can submit to NetOrca again."
            confirm({
                icon: <></>,
                content: (
                    <>
                        {
                            ["REJECTED", "ERROR"].includes(newState) &&
                            <>
                                <Alert message={alertMessage} type="warning"/>
                                <br/>
                            </>
                        }
                        {logModalMessageGenerator(newState)}
                        <Form form={logForm}>
                            <Form.Item name={"log"} initialValue={""}>
                                <TextArea rows={4} name="log"/>
                            </Form.Item>
                        </Form>
                    </>
                ),
                onOk() { updateCI() },
            });
        } else {
            updateCI()
        }
    }

    const CompleteChangeInstanceModal = () => (
        <Modal title="Complete Change Instance"
            visible={isUpdateFormVisible}
            footer={[
                <Button key="back" onClick={() => setIsUpdateFormVisible(false)}>Cancel</Button>,
                <Button key="submit" type="primary" onClick={completeChangeInstanceForm.submit}>Complete</Button>
            ]}
            closable={false}
        >
            <Form
                form={completeChangeInstanceForm}
                name="update_change"
                className="row-col"
                onFinish={onSubmitCompleteChangeInstance}
                layout="vertical"
            >
                <Form.Item label="Deployed Item JSON: (optional)" id='deployed_item' name="deployed_item">
                    <Editor height="30vh" defaultLanguage="json" defaultValue="{}" />
                </Form.Item>
                {logModalMessageGenerator("COMPLETED")}
                <Form.Item name={"log"} initialValue={""}>
                    <TextArea rows={4} name="log"/>
                </Form.Item>
            </Form>
        </Modal>
    )
    const permissionChangeState = ["ADMIN", "GENERAL"].includes(permissions);

    return (
        <Card>
            <ChangeInstanceLog change_instance={record} />
            {record.state == 'PENDING' && permissionChangeState && record.is_dependant == true &&
                <Space style={{ margin: '10px' }}> <WarningOutlined /> Dependant pending change instances must be approved or rejected by the main owner</Space>
            }
            <div style={{ float: 'right' }}>
                {record.state == 'PENDING' && permissionChangeState && record.is_dependant == false && record?.service?.allow_manual_approval &&
                    <Button onClick={() => updateChangeInstance(record, "APPROVED")} style={{ margin: '10px' }} icon={<CheckCircleOutlined />} type="primary">Approve</Button>
                }
                {record.state == 'PENDING' && permissionChangeState && record.is_dependant == false &&
                    <Button onClick={() => updateChangeInstance(record, "REJECTED")} style={{ margin: '10px' }} icon={<WarningOutlined />} >Reject</Button>
                }
                {record.state == 'APPROVED' && permissionChangeState && record?.service?.allow_manual_completion &&
                    <Button onClick={() => setIsUpdateFormVisible(true)} style={{ margin: '10px' }} type="primary" icon={<CheckCircleOutlined />} >Complete</Button>
                }
                {record.state == 'APPROVED' && permissionChangeState &&
                    <Button onClick={() => updateChangeInstance(record, "ERROR")}  style={{ margin: '10px' }} icon={<WarningOutlined />} >Mark as Error</Button>
                }
                {["ERROR", "REJECTED"].includes(record.state) && permissionChangeState &&
                    <Button onClick={() => updateChangeInstance(record, "CLOSED")} style={{ margin: '10px' }} icon={<CheckCircleOutlined />} type="primary">Close</Button>
                }
            </div>
            <CompleteChangeInstanceModal />
            <DeclarationDiff change_instance={record} />
        </Card>
    )
}

export default ChangeInstanceRow;