import React from 'react';
import { Form, Input, Button, Switch } from 'antd';
import {useDataProvider, useNotify, useQuery, useRefresh} from 'react-admin';
import {ExtendedDataProvider} from "../../types";
import {createSubmitHandler} from "@src/components/Settings/utils";

const FeedbackSettingsForm = () => {
  const notify = useNotify()
  const [form] = Form.useForm();
  const refresh = useRefresh();
  const dataProvider: ExtendedDataProvider = useDataProvider()
  const settingsName = "Feedback"

  const {loaded, error, data: currentSettings} = useQuery ({
    type: 'getOne',
    resource: 'general_settings',
    payload: {id: settingsName}
  });


  return (
    <>
        {
          (loaded || error) &&
      <Form form={form}
            labelAlign='left'
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            initialValues={{
              ENABLED: currentSettings?.['config']?.ENABLED
            }}
            onFinish={createSubmitHandler(settingsName, currentSettings, dataProvider, notify, refresh)}
      >

        <Form.Item valuePropName='checked' label="Enable Feedback" name="ENABLED" initialValue={currentSettings?.['config']?.ENABLED || false}>
          <Switch defaultChecked={currentSettings?.['config']?.ENABLED == true} />
        </Form.Item >

        <Form.Item
          label="Feedback Link"
          initialValue={currentSettings?.['config']?.feedback_link}
          name="feedback_link"
          rules={[{ required: true, message: 'Please input feedback link' }]}
        >
          <Input placeholder="e.g. jira.com" />
        </Form.Item>

        <Form.Item >
          <Button type="primary" htmlType="submit" className="form-button">Save</Button>
        </Form.Item>
      </Form>
        }
    </>
  )
}

export default FeedbackSettingsForm;
