import React from 'react';
import { Form, Input, Button, Switch } from 'antd';
import {useDataProvider, useNotify, useQuery, useRefresh} from 'react-admin';
import {ExtendedDataProvider} from "../../types";
import {createSubmitHandler} from "@src/components/Settings/utils";

const GoogleOAuthSettingsForm = () => {
    const notify = useNotify()
    const [form] = Form.useForm();
    const refresh = useRefresh();
    const dataProvider: ExtendedDataProvider = useDataProvider()
    const settingsName = "GoogleOAuth"

    const {loaded, error, data: currentSettings} = useQuery ({
        type: 'getOne',
        resource: 'general_settings',
        payload: {id: settingsName}
    });


    return (
      <>
        {
            (loaded || error) &&
          <Form form={form}
                labelAlign='left'
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                initialValues={{
                    ENABLED: currentSettings?.['config']?.ENABLED
                }}
                onFinish={createSubmitHandler(settingsName, currentSettings, dataProvider, notify, refresh)}
          >

              <Form.Item valuePropName='checked' label="Enable GoogleOAuth" name="ENABLED" initialValue={currentSettings?.['config']?.ENABLED || false}>
                  <Switch defaultChecked={currentSettings?.['config']?.ENABLED == true} />
              </Form.Item >

              <Form.Item
                label="Client ID"
                initialValue={currentSettings?.['config']?.CLIENT_ID}
                name="CLIENT_ID"
                rules={[{ required: true, message: 'Please input client id' }]}
              >
                  <Input />
              </Form.Item>

              <Form.Item
                label="Client SECRET"
                initialValue={currentSettings?.['config']?.CLIENT_SECRET}
                name="CLIENT_SECRET"
                rules={[{ required: true, message: 'Please input client secret' }]}
              >
                  <Input />
              </Form.Item>

              <Form.Item >
                  <Button type="primary" htmlType="submit" className="form-button">Save</Button>
              </Form.Item>
          </Form>
        }
      </>
    )
}

export default GoogleOAuthSettingsForm;
