import useGetGeneralSettings from "../../providers/useGetGeneralSettings";
import useGetIdentitySync from "./CustomHooks";
import { Empty } from "antd";
import React from "react";


export const getPOVResources
    : (pov: ("consumer" | "serviceowner")) => ({
    applicationsResource?: "owner_applications"|"applications",
    serviceItemsResource?: "owner_service_items"|"service_items",
    teamsResource?: "consumer_teams",
    servicesResource?: "consumer_services"|"services",
})
    = (pov: "consumer" | "serviceowner") => {
    if(pov==="consumer")
        return {
            servicesResource: "consumer_services",
            applicationsResource: "applications",
            serviceItemsResource: "service_items",
        }
    else if(pov==="serviceowner")
        return {
            teamsResource: "consumer_teams",
            servicesResource: "services",
            applicationsResource: "owner_applications",
            serviceItemsResource: "owner_service_items",
        }
    return {}
}


export const getCurrency = ():string => {
    const generalSettings = useGetGeneralSettings();

    let currency = generalSettings.find(setting => setting.name=='Currency');
    if(currency?.config?.ENABLED)
        return currency.config.currency;
    // default
    return "GBP";
}


export const setActiveTeamLocalStorage = (activeTeamData: any) => {
    const userLocalStorage = useGetIdentitySync ()
    userLocalStorage["active_team"] = activeTeamData
    localStorage.setItem ('user', JSON.stringify (userLocalStorage))
}


export const getActiveTeamLocalStorage = () => {
    // if user has no team, it returns undefined
    const identity = useGetIdentitySync ()
    return identity?.active_team
}


export const NotFoundComponent = <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
