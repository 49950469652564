import { TimeUnit } from 'chart.js';
import { useEffect, useState } from 'react';
import { useDataProvider } from 'react-admin';
import { ExtendedDataProvider } from '../../types';

const useGetGraphStatistics = (filterValues: any, setFilterValues: Function, pov: 'CONSUMER' | 'SERVICE_OWNER') => {
  const [graphIntervalUnit, setGraphIntervalUnit] = useState<TimeUnit>('month')
  const dataProvider: ExtendedDataProvider = useDataProvider()
  const [isLoaded, setIsLoaded] = useState(false)
  const [avgTimeCompletionGraphData, setAvgTimeCompletionGraphData] = useState<any>({})
  const [processedChangesGraphData, setProcessedChangesGraphData] = useState<any>({})
  const timeUnits = { 'month': 'monthly', 'day': 'daily', 'week': 'weekly', 'year': 'yearly' }

  const getYAxisScale = (values: number[]) => {
    // const yScale = Math.max(...values) - Math.min(...values)
    const yScale = Math.max(...values)
    const rangeCoef = 4

    if (yScale/rangeCoef <= 60) {
      // less than a minute
      return { label: "Seconds", data: values };

    } else if (yScale/rangeCoef <= (60 * 60)) {
      // less than an hour
      return { label: "Minutes", data: values.map((v) =>  v/60 ) }

    } else if (yScale/rangeCoef <= (60 * 60 * 24)) {
      // less than a day
      return { label: "Hours", data: values.map((v) =>  v/(3600) ) }
    }

    return { label: "Days", data: values.map((v) =>  v/(60*60*24) ) }
  }

  useEffect(() => {
    const endpoint = pov === 'CONSUMER' ? 'consumer_statistics' : 'serviceowner_statistics'
    dataProvider.getAction(endpoint,
      {
        verb: 'graphs',
        filter: {
          ...filterValues,
          'time_range': '1y',
          /**@ts-ignore */
          'time_interval': timeUnits[graphIntervalUnit]
        }
      })
      .then((response: any) => {
        const labels: any = []
        const _completionAvgValues: any = []
        const _totalCompletedValues: any = []
        response.data.cis_completed.map((entry: any) => {
          _completionAvgValues.push(entry.average)
          _totalCompletedValues.push(entry.count)
          const newDate = new Date(entry.created_min)
          if (graphIntervalUnit == 'month') {
            newDate.setDate(1)
          }
          labels.push(newDate.toLocaleDateString())
        })

        const label_data = getYAxisScale(_completionAvgValues)
        setAvgTimeCompletionGraphData({
          labels,
          datasets: [{
            label: 'Average',
            tension: 0,
            data: label_data.data,
            borderColor: '#5796cc',
          }],
          yLabel: label_data.label
        })

        setProcessedChangesGraphData({
          labels,
          datasets: [{
            label: 'Changes created',
            data: _totalCompletedValues,
            backgroundColor: 'rgba(255, 99, 132, 0.5)',
          }],
        })

        setIsLoaded(true)
      })
      .catch((error: any) => {
        console.error(error)
      })


  }, [pov, graphIntervalUnit, filterValues])

  return {
    setGraphIntervalUnit,
    graphIntervalUnit,
    avgTimeCompletionGraphData,
    processedChangesGraphData,
    isLoaded,
    setFilterValues
  }

}

export default useGetGraphStatistics;