import {useDataProvider, useNotify, useQueryWithStore, useRefresh} from "react-admin";
import React from "react";
import {Button, Form, Switch} from "antd";
import {ExtendedDataProvider} from "../../types";
import {createSubmitHandler} from "@src/components/Settings/utils";

const ChargesSettingsForm = () => {
    const notify = useNotify ()
    const [form] = Form.useForm ();
    const refresh = useRefresh();
    const dataProvider: ExtendedDataProvider = useDataProvider ()
    const settingsName = "ChargesSettings"

    const {loaded, error, data: currentSettings} = useQueryWithStore ({
        type: 'getOne',
        resource: 'general_settings',
        payload: {id: settingsName}
    });

    return (
        <>
            {
                (loaded || error) &&
                <Form
                    form={form}
                    labelAlign='left'
                    labelCol={{span: 8}}
                    wrapperCol={{span: 16}}
                    initialValues={{ ENABLED: currentSettings?.['config']?.ENABLED }}
                    onFinish={createSubmitHandler(settingsName, currentSettings, dataProvider, notify, refresh)}
                >

                    <Form.Item valuePropName='checked' label={"Enable " + settingsName} name="ENABLED" initialValue={currentSettings?.['config']?.ENABLED || false}>
                        <Switch defaultChecked={currentSettings?.['config']?.ENABLED == true}/>
                    </Form.Item>

                    <Form.Item
                        label={"Hide for Service Owners"}
                        initialValue={currentSettings?.['config']?.hide_for_serviceowners || false}
                        name="hide_for_serviceowners"
                        rules={[{required: true}]}
                    >
                        <Switch defaultChecked={currentSettings?.['config']?.hide_for_serviceowners == true}/>
                    </Form.Item>

                    <Form.Item
                        label={"Hide for Consumers"}
                        initialValue={currentSettings?.['config']?.hide_for_consumers || false}
                        name="hide_for_consumers"
                        rules={[{required: true}]}
                    >
                        <Switch defaultChecked={currentSettings?.['config']?.hide_for_consumers == true}/>
                    </Form.Item>

                    <Form.Item
                        label={"Show Only to Admins"}
                        initialValue={currentSettings?.['config']?.only_admins_can_see || false}
                        name="only_admins_can_see"
                        rules={[{required: true}]}
                    >
                        <Switch defaultChecked={currentSettings?.['config']?.only_admins_can_see == true}/>
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" htmlType="submit" className="form-button">Save</Button>
                    </Form.Item>
                </Form>
            }
        </>
    )
}

export default ChargesSettingsForm;
