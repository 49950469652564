import {useDataProvider, useNotify, usePermissions, useQuery} from "react-admin";
import React from "react";
import {Button, Card, Form, Input, Select, Switch, Tabs} from "antd";
import ReactJson from "react-json-view";
import ManageAllowedTeams from "./ManageAllowedTeams";
import ManageDependantTeams from "./ManageDependantTeams";
import ManageServiceConfigs from "./ManageServiceConfigs";

const { TabPane } = Tabs;
const { Option } = Select;

const ServiceRow = ({ record }: { record: Record<string, any> }) => {
    const {permissions} = usePermissions ();
    const [form] = Form.useForm();
    const dataProvider = useDataProvider();
    const notify = useNotify ();

    const { data: schemasVersions, loaded: versionsLoaded, error: versionsError } = useQuery({
        type: 'getAction',
        resource: 'service_schemas',
        payload: { verb: 'versions', filter: { service_id: record.id }}
    });

    const updateService = (values: any) => {
        dataProvider.update('services', { id: values.id, data: values, previousData: { id: values.id } })
            .then(response => notify('Service settings have been updated'))
            .catch(error => notify("Something went wrong", "warning"))
    }

    return (
        <>
            {
                record &&
                <Tabs defaultActiveKey="1" type='card'>
                    <TabPane tab="Schema" key="1">
                        <Card>
                            <ReactJson style={{ padding: '20px' }} src={record.schema.schema} displayDataTypes={false} name="schema" displayArrayKey={false} displayObjectSize={false} />
                        </Card>
                    </TabPane>
                    <TabPane tab="Settings" key="2">
                        <Card style={{ padding: '20px' }}>
                            <Form onFinish={updateService} labelCol={{span: 5}} labelAlign={'left'} form={form}>
                                <Form.Item valuePropName='checked' label="Approval Required" name="approval_required">
                                    <Switch defaultChecked={record.approval_required} disabled={permissions !== "ADMIN"}/>
                                </Form.Item >

                                <Form.Item valuePropName='checked' label="Enable Email Notifications" name="enabled_notifications">
                                    <Switch defaultChecked={record.enabled_notifications} disabled={permissions !== "ADMIN"} />
                                </Form.Item >

                                <Form.Item valuePropName='checked' label="Allow Manual Approval" name="allow_manual_approval">
                                    <Switch defaultChecked={record.allow_manual_approval} disabled={permissions !== "ADMIN"} />
                                </Form.Item >

                                <Form.Item valuePropName='checked' label="Allow Manual Completion" name="allow_manual_completion">
                                    <Switch defaultChecked={record.allow_manual_completion} disabled={permissions !== "ADMIN"} />
                                </Form.Item >

                                <Form.Item label="Minimum Schema Version" name="minimum_version">
                                    <Select defaultValue={record.minimum_version} style={{ width: '150px' }} placeholder="Select Version" disabled={permissions !== "ADMIN"}>
                                        <Option key={0} value={0}>Latest</Option>
                                        {
                                            schemasVersions &&
                                            Object.entries(schemasVersions).map(([key, value], index) => (
                                                // @ts-ignore
                                                <Option key={value.version} value={value.version}>{value.version}</Option>
                                            ))
                                        }
                                    </Select>
                                </Form.Item>
                                <Form.Item>
                                    {
                                        permissions === "ADMIN" &&
                                        <Button type="primary" htmlType="submit">Save Changes</Button>
                                    }
                                </Form.Item>
                                <Form.Item hidden id="id" name="id" initialValue={record.id}>
                                    <Input />
                                </Form.Item>
                            </Form>
                        </Card>
                    </TabPane>
                    <TabPane tab="Allowed Teams" key="3">
                        <ManageAllowedTeams record={record} />
                    </TabPane>
                    <TabPane tab="Dependant Teams" key="4">
                        <ManageDependantTeams record={record} />
                    </TabPane>
                    <TabPane tab="Service Configs" key="5">
                        <ManageServiceConfigs record={record} />
                    </TabPane>
                </Tabs>
            }
        </>
    )
}

export default ServiceRow;
